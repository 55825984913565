*, :before, :after {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
}

body {
  background-color: #f7f7f7;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  font-family: "Press Start 2P", cursive;
  display: flex;
}

.world {
  position: relative;
  overflow: hidden;
}

.score {
  color: #535353;
  font-size: 2vmin;
  position: absolute;
  top: 1vmin;
  right: 1vmin;
}

.separator {
  color: #535353;
  font-size: 2vmin;
  position: absolute;
  top: 1vmin;
  right: 7vmin;
}

.start-screen {
  color: #535353;
  font-size: 2vmin;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hide {
  display: none;
}

.ground {
  --left: 0;
  bottom: 0;
  left: calc(var(--left) * 1%);
  width: 300%;
  position: absolute;
}

.clouds {
  --left: 0;
  top: 0;
  left: calc(var(--left) * 1%);
  width: 150%;
  position: absolute;
}

.dino {
  --bottom: 0;
  left: 1%;
  bottom: calc(var(--bottom) * 1%);
  height: 30%;
  position: absolute;
}

.cactus {
  left: calc(var(--left) * 1%);
  height: 30%;
  position: absolute;
  bottom: 0;
}

.name {
  background-color: #0000;
  border: 0;
  font-family: "Press Start 2P", cursive;
  font-size: 2vmin;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.highscore-holder {
  color: #535353;
  text-align: left;
  font-size: 2vmin;
  position: absolute;
  top: 1vmin;
  right: 10vmin;
}

table {
  border-spacing: 10px;
  border-collapse: collapse;
  width: 100%;
  padding: 100px;
}
/*# sourceMappingURL=index.607d686f.css.map */
